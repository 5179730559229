/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


.entering{
    z-index:10000;
    position: none;
    transition: all 0.4s ease;
    transform: translateY(0);
    opacity: 1;
    background-image: url("https://i.pinimg.com/originals/0b/1f/85/0b1f8517c066dd89ff8022c31bf23621.jpg");
    background-size: cover;
    @media only screen and (min-width: 600px) {
      padding: 0px calc( ( 100vw - 600px ) / 2 );
    }
  }
  
  .enteringx{
    z-index:-10;
    position: fixed;
    transition: all 0.4s ease;
    transform: translateY(-1000px);
    opacity: 0;
    background-image: url("https://i.pinimg.com/originals/0b/1f/85/0b1f8517c066dd89ff8022c31bf23621.jpg");
    background-size: cover;
    @media only screen and (min-width: 600px) {
      padding: 0px calc( ( 100vw - 600px ) / 2 );
    }
  }
  
  .entering2{
    z-index:10000;
    position: none;
    transition: all 0.4s ease;
    transform: translateY(0);
    opacity: 1;
    background-image: url("https://i.pinimg.com/originals/0b/1f/85/0b1f8517c066dd89ff8022c31bf23621.jpg");
    background-size: cover;
    @media only screen and (min-width: 600px) {
      padding: 0px calc( ( 100vw - 600px ) / 2 );
    }
  }
  
  .entering2x{
    z-index:-10;
    position: fixed;
    transition: all 0.4s ease;
    transform: translateY(1000px);
    opacity: 0;
    background-image: url("https://i.pinimg.com/originals/0b/1f/85/0b1f8517c066dd89ff8022c31bf23621.jpg");
    background-size: cover;
    @media only screen and (min-width: 600px) {
      padding: 0px calc( ( 100vw - 600px ) / 2 );
    }
  }
  
  .highlight{
    color:#f53c3c;border:2px solid #f53c3c;background-color: rgba(#f53c3c,0.1);
  }
  
  .highlightx{
    color:#666666;border:2px solid #eeeeee;
  }
  
  .coming{
    transition: all .2s ease;
    // transform: scale(1);
    filter: grayscale(0);
    opacity: 1;
  }
  
  .comingx{
    transition: all .2s ease;
    // transform: scale(0);
    filter: grayscale(1);
    opacity: 0.2;
  }
  
  .greener{
    color:#00b871;
    border:2px solid #00b871;
    background-color: rgba(#00b871,0.1);
  }
  
  .greenerx{
    color:#999999;
    border:2px solid #eeeeee;
  }

  .highhigh{
    background-color: #00b871;color:white;
  }

  .highhighx{
    background-color: #999999;color:white;
  }